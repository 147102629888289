import React, { memo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { clearQrCode, getPaymentQR } from '../../../../../redux/ducks/payment';
import QrSbpModalForm from './Components/QrSbpModalForm';
import QrSbpModalFile from './Components/QrSbpModalFile';
import moment from 'moment';

const QrSbpModal = ({ visible, onCancel, title, initVals, initSum, payment, recPayment }) => {
  const qr = useSelector((state) => state.payment.qr);
  const link = useSelector((state) => state.payment.link);
  const dispatch = useDispatch();
  // antd Form
  const [form] = Form.useForm();
  //   Очистка формы
  const clearModal = useCallback(() => {
    form.resetFields();
  }, [form]);
  //   Закрытие формы
  const closeConfirm = () => {
    let values = form.getFieldsValue();
    JSON.stringify(values) === '{}';
    console.log(JSON.stringify(values))
      ? onCancel()
      : Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          content:
            'Вы действительно хотите прервать процесс оплаты? Введенные данные не сохранятся.',
          okText: 'Да',
          cancelText: 'Нет',
          centered: true,
          onOk: () => {
            onCancel();
            dispatch(clearQrCode());
            setTimeout(() => {
              clearModal();
            }, 200);
          },
        });
  };
  // Подтверждение
  const getQRCode = useCallback(
    (values) => {
      const vals = {
        ...initVals,
        ...values,
        ДатаОплаты: moment().format('yyyy-MM-DD'),
        ОтчетБланк: false,
        ОтчетКод: true,
      };
      dispatch(getPaymentQR({ vals }));
    },
    [dispatch, initVals],
  );

  useEffect(() => {
    if (initVals.Сумма !== 0) {
      getQRCode();
    }
  }, [getQRCode, visible, initVals]);
  return (
    <Modal
      open={visible}
      title={title}
      okText='Подтвердить'
      cancelText='Выйти'
      width={'400px'}
      onCancel={() => closeConfirm()}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            getQRCode(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      className={qr === null ? 'change-password-modal' : 'info-loan-modal-sbp'}
      centered
    >
      {qr === null ? (
        <QrSbpModalForm
          form={form}
          initSum={initSum}
          visible={visible}
          initVals={initVals}
          payment={recPayment}
          recPayment={payment}
        />
      ) : (
        <QrSbpModalFile qr={qr} link={link} />
      )}
    </Modal>
  );
};
export default memo(QrSbpModal);
